<template>
   <div>
      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_1 font_bold mb_23">Posture Perfect </h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item">
                              <router-link :to=" { name: 'home' }">Home</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to=" { name: 'ergo_essentials' }">Ergo Essentials</router-link>
                           </li>
                           <li class="breadcrumb-item active" aria-current="page">Posture Perfect</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <!-- pagination Starts -->
      <section class="pagination_sec">
         <div class="pagination-nav">
            <div class="container-fluid custome_container">
               <div class="row justify-content-center">
                  <div class="col-md-11">
                     <div class="row justify-content-between">
                        <div class="col-md-12">
                           <nav aria-label="Page navigation example">
                              <ul class="pagination justify-content-between">
                                 <li class="page-item">
                                    <a class="d-none page-link border-0 text_black"  aria-label="Previous">
                                       <span aria-hidden="true">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29.631" viewBox="0 0 43.575 29.631">
                                          <g id="Left_arrow" data-name="Left arrow" transform="translate(2.75 3.967)">
                                            <path id="Path_30" data-name="Path 30" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1744.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                            <path id="Path_31" data-name="Path 31" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1728.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                          </g>
                                       </svg>
                                          Previous
                                       </span>
                                    </a>
                                 </li>
                                 <li class="page-item">
                                    <router-link class="page-link border-0 text_black" aria-label="Next"
                                       :to="{ name: 'sitting_pretty' }">
                                       <span aria-hidden="true" class="font_bold">
                                          <span class="text_primary">Up Next<span class="mobile_hide">:</span></span>
                                          <span class="mobile_hide"> Sitting Pretty</span>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 33.575 29.631" style="margin-left:5px;">
                                             <g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)">
                                               <path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                               <path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                             </g>
                                          </svg>
                                       </span>
                                    </router-link>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- pagination Ends -->
      <!-- image text start -->
      <section class="image_text_sec without_image bg_grey pt_50 pb_50 mt_30">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-7">
                  <div class="image_text_right_side">
                     <h2 class="mb_22">Be the envy of all your friends!</h2>
                     <p class="text_black mb_40">
                        Good posture helps you be more productive, improves your mood, is healthier for your body, and
                        makes you look like a million bucks. Start with aligning your body and then build your workspace
                        up to support your new upright and balanced posture.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      <div class="text-center down_to_section newarrow">
            <a   v-scroll-to="{
     el: '#accordian_secc',
     duration: 500,
     easing: 'linear',
     offset: -350,
 }">
                             <svg xmlns="http://www.w3.org/2000/svg" width="50.132" height="35.219" viewBox="0 0 57.132 61.219">
  <g id="Down_arrow" data-name="Down arrow" transform="translate(-20020.894 -12891.354) rotate(90)">
    <path id="Path_30" data-name="Path 30" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11180.239 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
    <path id="Path_31" data-name="Path 31" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11150.357 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
  </g>
</svg>
            </a>
         </div>
      </section>
      <!-- Set Yourself up for success Ends -->
      <!-- Accordion  start -->
      <section id="accordian_secc" class="accordion_sec my_80">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xl-8">
                  <div class="accordion" id="accordionExample">
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                              <img src="../assets/images/accordion_icon.png" alt="img"> Is your posture in line with your activity? </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <p>
                                 <strong>Reclined posture</strong> is perfect for passive activities like reading, watching videos, or relaxing.
                              </p>
                              <p>
                                 <strong>Forward and supported posture</strong> is great for activities like writing, drawing, creating to-do lists, and even doodling.
                              </p>
                              <p>
                                <strong>Upright balanced posture</strong> is best for working with a computer, laptop or doing desktop work.
                              </p>
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              <img src="../assets/images/user_chair.png" alt="img"> How is your alignment?
                           </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">

                              <div class="d-flex justify-content-start flex-wrap flex-lg-nowrap posture_p">

                                 <div class="image"><img src="../assets/images/how_is_your_alignment.png" alt="" class="img-fluid"></div>
                                 <div class="media-body">
                                     <div class="claim_text_box mb_60"><p class="text_black mb_34"><strong>When you sit, strive to create a plumb line from your ears to your hips.</strong> Start by aligning your</p>
                                     <ul class="pl_18 mb_0 text_balticsea">
                                    <li><p  class="mb_14">Ears over your shoulders (keep your head balanced over the plane of your spine. </p></li>
                                     <li><p  class="mb_14">Shoulders over your elbows (with elbows close to your sides)</p></li>
                                     <li><p  class="mb_14">Elbows over your hips</p></li>
                                     </ul>

                                     

                                     </div>

                                     <div class="claim_text_box"><p class="text_black mb_34"><strong>When you stand, your plumb line continues:</strong> </p>
                                     <ul class="pl_18 mb_0 text_balticsea">
                                    <li><p  class="mb_14">Hips over your knees</p></li>
                                     <li><p  class="mb_14">Knees over your ankles</p></li>
                                     </ul>

                                     

                                     </div>

                                 </div>
                              </div>
                             
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              <img src="../assets/images/user_table.png" alt="img"> Check out these sit/stand posture essentials.</button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <div class="claim_text_box mb_60"><p class="text_black mb_34">These posture essentials will help you when you sit and stand.</p>
                                     <ul class="pl_18 mb_0 text_balticsea">
                                    <li><p class="mb_14">Your feet should be flat on the floor or on a footrest for a stable base of support</p></li>
                                     <li><p  class="mb_14">Your pelvis should be neutral </p></li>
                                     <li><p  class="mb_14">Your items should always be close to you</p></li>
                                     <li><p  class="mb_14">Your keyboard and mouse should be at elbow height </p></li>
                                     <li><p  class="mb_14">Your screens should be placed at or slightly lower than eye level </p></li>
                                     <li><p  class="mb_14">Alternate between sitting and standing </p></li>
                                     </ul>
                                     </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Accordion  end -->
      <!-- more WFH tips start  -->
      <section class="more_lab_tips bg_grey pt_42">
         <div class="container">
            <div class="row justify-content-center ">
               <div class="col-md-12 col-xl-11 px_20">
                  <div class="row">
                     <div class="col-md-12">
                        <h4 class="heading_4 font_bold mb-0">More Office Tips</h4>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'posture_perfect' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect1.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Posture Perfect</h6>
                                 <p>Enjoy having the best posture in the room. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'sitting_pretty' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect2.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Sitting Pretty </h6>
                                 <p>Learn how to sit in total comfort day in and day out.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'sit_stand_basics' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect3.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Sit/Stand Basics</h6>
                                 <p>Do you sit or stand when you work? How about a bit of both? </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'typing_dos_and_donts' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect4.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Typing Do’s and Don’ts</h6>
                                 <p>These typing tips will protect your hands and speed you up. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'mousing_dos_and_donts' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect5.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Mousing Do’s &amp; Don’ts</h6>
                                 <p>Healthy mousing is key to your well being and productivity. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'mindful_ergonomics' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect6.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Mindful Ergonomics</h6>
                                 <p>Being mindful of how you do what you do is key to staying healthy. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- more WFH tips end  -->
   </div>
</template>

<script>
   export default {
      name: "posture_perfect",
      mounted(){
         document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      }
   }
</script>